import React from 'react';
import Main from '../src/Page/Main'


function App() {
  return (
    <div className="App">
        <Main />
    </div>
  );
}

export default App;
